<template>
    <div class="conteiner">
        <div class="cont-404">
            <router-link to="/" class="logo big-logo">MINT 404</router-link>
            <br>
            Такой страницы не существует
            <br>
            <router-link to="/">На главную</router-link>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: "Mint - калькулятор вилок, который всегда перед глазами"
        }
    }
}
</script>

<style scoped>
    .conteiner{
        position: absolute;
        height: 85%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
    }
    .big-logo{
        font-size: 60px;
    }
    .cont-404{
        text-align: center;
    }
</style>